html,
body {
  padding: 0;
  margin: 0;
  overflow-y: hidden;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

h2{
  font-family: 'Montserrat', sans-serif!important;
  font-weight: 700!important;
}

p,a{
  font-family: 'Montserrat', sans-serif!important;
  font-weight: 400!important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


.keuzehulp-col{
  padding-bottom: 16.5%!important;
  padding-top: 13%!important;
  background-color: #fff;
  padding: 5%;
  text-align: center;
  border-bottom: 5px solid #318ce7;
  height: 526px;
}

.keuzehulp-col h2{
  font-size: 2.5em;
}

.keuzehulp-col p{
  font-size: 1em;
  margin-top: 3%;
  margin-bottom: 6%;
}

.keuzehulp-col button{
  border-radius: 0px;
  background-color: #318CE7;
}

.choose-buttons{
  padding-left: 8%!important;
  padding-right: 8%!important;
}
.choose-buttons button{
  color: black;
  margin-left: 4%;
  margin-right: 4%;
  margin-top: 6%;
  background-color: #EDEDED;
}

.choose-buttons button:hover{
  color:white;
}

.sprankle-blue{
  color: #318CE7;
}

.keuzehulp-col .MuiFormControl-root{
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 5%;
  margin-top: -5%;
}

.close{
  background-color: transparent!important;
  float: right;
  color:red!important;
  position: absolute!important;
  z-index: 99;
  right: 40px!important;
  top: 40px!important;
  margin-top: 0%!important;
}

.back{
  background-color: #318CE7!important;
  float: right;
  color:white!important;
  position: absolute!important;
  z-index: 99;
  left: 40px!important;
  top: 40px!important;
  margin-top: 0%!important;
}


@media only screen and (max-width: 600px) {
  .keuzehulp-col h2{
    font-size: 2em;
  }
  .keuzehulp-col{
 
    margin-top: 50%!important;
    
  }
}